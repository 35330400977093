<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs" :isShowBack="false"></ABreadcrumb>
    <!-- EOC -->
    <AError
      v-if="userApi.isError"
      :api="userApi"
      :callbackReset="() => (userApi.isError = false)"
    ></AError>
    <AError
      v-if="classroomApi.isError"
      :api="classroomApi"
      :callbackReset="() => (classroomApi.isError = false)"
    ></AError>
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <div class="d-flex justify-space-between mb-3">
        <div class="text-h6">My Institutions</div>
        <div>
          <!-- <v-btn
            width="150px"
            rounded
            color="primary"
            :to="{ name: 'PageEducatorInstitutionAdd' }"
            dark
          >
            + New
          </v-btn> -->
        </div>
      </div>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <ALoader :isLoading="institutionApi.isLoading"></ALoader>
          <v-list>
            <v-list-item
              v-if="
                institutions.length == 0 &&
                !institutionApi.isLoading &&
                applications.length == 0 &&
                !applicationApi.isLoading
              "
            >
              <v-list-item-content>
                <v-list-item-title class="text-center"
                  >No Items.</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(application, i) in applications"
              :key="i"
              class="menu-item"
            >
              <v-list-item-avatar class="pr-6">
                <v-avatar color="black" size="30">
                  <span class="white--text text-h6">{{
                    application.institutionName.charAt(0).toUpperCase()
                  }}</span></v-avatar
                >
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle
                  class="text-left"
                  v-if="application.status == 'pending'"
                >
                  <v-chip x-small>
                    Pending Approval</v-chip
                  ></v-list-item-subtitle
                >
                <v-list-item-subtitle
                  class="text-left"
                  v-else-if="application.status == 'rejected'"
                >
                  <v-chip x-small>
                    Application Rejected</v-chip
                  ></v-list-item-subtitle
                >
                <v-list-item-subtitle
                  class="text-left"
                  v-else-if="application.status == 'canceled'"
                >
                  <v-chip x-small>
                    Application Canceled</v-chip
                  ></v-list-item-subtitle
                >

                <v-list-item-title
                  class="text-left"
                  v-text="application.institutionName"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  @click="clickApplication(application)"
                  small
                  color="primary"
                  rounded
                  >View</v-btn
                >
                </v-list-item-action
              >
            </v-list-item>
            <v-list-item
              v-for="(institution, i) in institutions"
              :key="i"
              class="menu-item"
            >
              <v-list-item-avatar class="pr-6">
                <v-avatar color="black" size="30">
                  <v-img v-if="institution.logoUrl" :src="institution.logoUrl"></v-img>
                  <span v-else class="white--text text-h6">{{institution.name.charAt(0).toUpperCase()}}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="text-left"
                  v-text="institution.name"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  @click="clickInstitution(institution)"
                  large
                  color="primary"
                  rounded
                  >View</v-btn
                ></v-list-item-action
              >
            </v-list-item>
            <!-- <v-list-item
              :to="{ name: 'PageEducatorInstitutionAdd' }"
              class="add-menu-item"
            >
              <v-list-item-content>
                <v-list-item-title class="text-center"
                  >+ Add Institution</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </div>
      </div>
    </v-card>
    <!-- <v-card class="mx-auto pa-3 mt-3" max-width="800" outlined min-height="200">
      <div class="d-flex justify-space-between mb-3">
        <div class="text-h6">My Private Classes</div>
        <div>
          <v-btn
            width="150px"
            rounded
            color="primary"
            :to="{ name: 'PageEducatorClassroomAdd' }"
            dark
          >
            + New
          </v-btn>
        </div>
      </div>

      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <ALoader :isLoading="classroomApi.isLoading"></ALoader>
          <v-list>
            <v-list-item
              v-if="classrooms.length == 0 && !classroomApi.isLoading"
            >
              <v-list-item-content>
                <v-list-item-title class="text-center"
                  >No Items.</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(classroom, i) in classrooms"
              :key="i"
              class="menu-item"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="text-left"
                  v-text="classroom.name"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn @click="clickClassroom(classroom)" small color="primary"
                  >View</v-btn
                ></v-list-item-action
              >
            </v-list-item>
            <v-list-item
              :to="{ name: 'PageEducatorClassroomAdd' }"
              class="add-menu-item"
            >
              <v-list-item-content>
                <v-list-item-title class="text-center"
                  >+ Add Class</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-card> -->
    <v-card class="mx-auto pa-3 mt-3" max-width="800" outlined min-height="200">
      <div class="d-flex justify-space-between mb-3">
        <div class="text-h6">My Family</div>
        <div v-if="users.length < 10">
          <v-btn
            rounded
            color="primary"
            :to="{ name: 'PageEducatorUserAdd' }"
            dark
          >
            + New Family Member
          </v-btn>
        </div>
      </div>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <div class="text-left text-caption">{{ users.length }}/10 Members</div>
          <ALoader :isLoading="userApi.isLoading"></ALoader>
          <v-list>
            <v-list-item v-if="users.length == 0 && !userApi.isLoading">
              <v-list-item-content>
                <v-list-item-title class="text-center"
                  >No Items.</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="(user, i) in users" :key="i" class="menu-item">
              <v-list-item-avatar class="pr-6">
                <AAvatar
                  v-if="getAvatar(user)"
                  :width="40"
                  :height="40"
                  :avatar="getAvatar(user).avatar"
                />
                <v-img
                  v-else
                  :max-height="40"
                  :max-width="40"
                  :src="require('@/assets/CS0040_default_F0.png')"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="text-left"
                  v-text="user.name"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn :href="gameUrl" target="_blank" rounded small color="yellow darken-2"
                  >Play</v-btn
                ></v-list-item-action
              >
              <v-list-item-action>
                
                  <v-menu
                    bottom
                    origin="center center"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      class="ml-4"
                      color="green"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        small
                        rounded
                      >
                        Share
                      </v-btn>
                    </template>
                    <v-list width="180px">
                      <v-list-item>
                        <v-list-item-action class="text-center">
                          <ShareNetwork
                            class="text-decoration-none"
                            network="whatsapp"
                            title="Game Invitation"
                            :url="gameUrl"
                          >
                            <v-btn width="150px" rounded color="green" dark>
                              <v-icon left dark> mdi-whatsapp </v-icon>
                              Whatsapp
                            </v-btn>
                          </ShareNetwork>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action
                          ><v-btn
                            width="150px"
                            :disabled="copied"
                            @click="copy"
                            rounded
                            color="primary"
                          >
                            <v-icon left dark> mdi-content-copy </v-icon>
                            <span v-if="copied">Copied</span>
                            <span v-else>{{$t('action.copy')}}</span>
                          </v-btn></v-list-item-action
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn
                  :to="{
                    name: 'PageEducatorUserRead',
                    params: { id: user.id },
                  }"
                  rounded
                  small
                  color="primary"
                  >View</v-btn
                >
              </v-list-item-action>
            </v-list-item>
            <!-- <v-list-item
              v-if="users.length < 10"
              :to="{ name: 'PageEducatorUserAdd' }"
              class="add-menu-item"
            >
              <v-list-item-content>
                <v-list-item-title class="text-center"
                  >+ Add your family member</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
      
      <script>
import { mapState } from "vuex";
import VueSocialSharing from "vue-social-sharing";
import Vue from "vue";
Vue.use(VueSocialSharing);
export default {
  components: {
    //
  },
  computed: {
    size() {
      const size = { xs: "x-small", sm: "small", lg: "small", xl: "small" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: [
    //
  ],
  data: () => ({
    copied: false,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[api]
    userApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    classroomApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    institutionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    applicationApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    playerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    selectedFilter: "all",
    step: 1,
    gameUrl: null,
    name: null,
    players: [],
    institutions: [],
    applications: [],
    classrooms: [],
    users: [],
    menus: [
      {
        text: "Users",
        icon: "mdi-google-classroom",
        route: "PageTestMyFamilyUser",
        count: 5,
        isLock: false,
      },
      {
        text: "Homeworks",
        icon: "mdi-bag-personal",
        route: null,
        count: 0,
        isLock: true,
      },
    ],
    group: {},
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: "Dashboard",
      to: { name: "PageEducatorDashboard" },
      exact: true,
    });
    //EOC
    this.userApi.method = "GET";
    this.userApi.url =
      this.$api.servers.sso + "/api/v1/" + this.$_getLocale() + "/backend/user";

    this.userApi.callbackReset = () => {
      this.userApi.isLoading = true;
      this.userApi.isError = false;
    };

    this.userApi.callbackError = (e) => {
      this.userApi.isLoading = false;
      this.userApi.isError = true;
      this.userApi.error = e;
    };

    this.userApi.callbackSuccess = (resp) => {
      this.userApi.isLoading = false;
      this.users = resp;
      this.$api.fetch(this.playerApi);
      // this.selectedCountry = this.$_.find(this.countries, {
      //   countryCode: this.countryCode,
      // });
    };

    this.classroomApi.method = "GET";
    this.classroomApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/classroom";

    this.classroomApi.callbackReset = () => {
      this.classroomApi.isLoading = true;
      this.classroomApi.isError = false;
    };

    this.classroomApi.callbackError = (e) => {
      this.classroomApi.isLoading = false;
      this.classroomApi.isError = true;
      this.classroomApi.error = e;
    };

    this.classroomApi.callbackSuccess = (resp) => {
      this.classroomApi.isLoading = false;
      this.classrooms = resp;
      // this.selectedCountry = this.$_.find(this.countries, {
      //   countryCode: this.countryCode,
      // });
    };

    this.institutionApi.method = "GET";
    this.institutionApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution";

    this.institutionApi.callbackReset = () => {
      this.institutionApi.isLoading = true;
      this.institutionApi.isError = false;
    };

    this.institutionApi.callbackError = (e) => {
      this.institutionApi.isLoading = false;
      this.institutionApi.isError = true;
      this.institutionApi.error = e;
    };

    this.institutionApi.callbackSuccess = (resp) => {
      this.institutionApi.isLoading = false;
      this.institutions = resp;
    };

    this.applicationApi.method = "GET";
    this.applicationApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/application";

    this.applicationApi.callbackReset = () => {
      this.applicationApi.isLoading = true;
      this.applicationApi.isError = false;
    };

    this.applicationApi.callbackError = (e) => {
      this.applicationApi.isLoading = false;
      this.applicationApi.isError = true;
      this.applicationApi.error = e;
    };

    this.applicationApi.callbackSuccess = (resp) => {
      this.applicationApi.isLoading = false;
      this.applications = resp;
    };

    this.playerApi.method = "GET";
    this.playerApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/backend/account/" +
      this.auth.Account.id +
      "/player";

    this.playerApi.callbackReset = () => {
      this.playerApi.isLoading = true;
      this.playerApi.isError = false;
    };

    this.playerApi.callbackError = (e) => {
      this.playerApi.isLoading = false;
      this.playerApi.isError = true;
      this.playerApi.error = e;
    };

    this.playerApi.callbackSuccess = (resp) => {
      this.playerApi.isLoading = false;
      this.players = resp;
      // this.selectedCountry = this.$_.find(this.countries, {
      //   countryCode: this.countryCode,
      // });
    };
    this.group = this.$_.find(this.auth.Groups, { type: "family" });
    this.gameUrl = `${process.env.VUE_APP_URL_WEB_GAME}/${this.$_getLocale()}/access?code=${this.group.code}`;
  },
  mounted() {
    this.$api.fetch(this.userApi);
    this.$api.fetch(this.classroomApi);
    this.$api.fetch(this.institutionApi);
    this.$api.fetch(this.applicationApi);
  },
  methods: {
    getAvatar(user) {
      let avatar = null;
      avatar = this.$_.find(this.players, function (n) {
        if (n.userId == user.id) {
          return n.avatar;
        }
      });
      return avatar;
    },
    selectFilter(item) {
      this.selectedFilter = item;
    },
    clickMenu(item) {
      this.$router.push({ name: item.route, params: { id: item.id } });
    },
    clickClassroom(item) {
      this.$router.push({
        name: "PageEducatorClassroomDashboard",
        params: { id: item.id },
      });
    },
    clickInstitution(item) {
      this.$router.push({
        name: "PageEducatorInstitutionDashboard",
        params: { id: item.id },
      });
    },
    clickApplication(item) {
      this.$router.push({
        name: "PageEducatorInstitutionApplicationRead",
        params: { id: item.id },
      });
    },
    copy() {
      navigator.clipboard.writeText(this.gameUrl);
      this.copied = true;
      var self = this;
      setTimeout(() => {
        if (self && self.copied) self.copied = false;
      }, 800);
    },
  },
};
</script>
  <style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.add-menu-item {
  border: 1px dashed black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
</style>